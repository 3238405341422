<template>
  <b-container>
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('customer.link')" />
      <GoldflamTable
        :new-item="newCustomerObject"
        :all-items="allCustomers"
        :table-fields="tableFields"
        :edit-function="editCustomerFunction"
        :delete-function="deleteCustomer"
        :merge-function="mergeCustomerFunction"
        :busy="datatableBusy"
        sort-by="fullName"
      />

      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('customer.title')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveCustomer(editCustomer)"
        :ok-disabled="$v.$invalid"
        @hidden="resetEditModal"
      >
        <b-form>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editName"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('customer.name')"
          >
            <b-form-input
              id="editName"
              type="text"
              v-model="$v.editCustomer.fullName.$model"
              :state="validateFormInput('fullName')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editCustomerCode"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('customer.customerCode')"
          >
            <b-form-input
              id="editCustomerCode"
              type="text"
              v-model="$v.editCustomer.customerCode.$model"
              :state="validateFormInput('customerCode')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editEMail"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.e-mail')"
            :invalid-feedback="$t('user.invalidFeedback-msg-email')"
          >
            <b-form-input
              id="editEMail"
              type="email"
              v-model="$v.editCustomer.email.$model"
              :state="validateFormInput('email')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editAddress"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('customer.address')"
          >
            <b-form-input
              id="editAddress"
              type="text"
              v-model="$v.editCustomer.address.$model"
              :state="validateFormInput('address')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editCity"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('customer.city')"
          >
            <b-form-input
              id="editCity"
              type="text"
              v-model="$v.editCustomer.city.$model"
              :state="validateFormInput('city')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editZipCode"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('customer.zipCode')"
          >
            <b-form-input
              id="editZipCode"
              type="text"
              v-model="$v.editCustomer.zipCode.$model"
              :state="validateFormInput('zipCode')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editHourlyRateInCent"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('customer.hourlyRate')"
          >
            <currency-input
              id="editHourlyRateInCent"
              :value="$v.editCustomer.hourlyRateInCents.$model"
              :locale="$store.getters.getLocale"
              @change="$v.editCustomer.hourlyRateInCents.$model = $event"
              :state="validateFormInput('hourlyRateInCents')"
            />
          </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
        id="mergeModal"
        v-model="modalControl.merge.show"
        size="xl"
        centered
        :title="$t('customer.merge-title')"
        :ok-title="$t('customer.merge-title')"
        ok-variant="warning"
        :ok-disabled="isSameCustomer || !customersSelected"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="mergeCustomer(merge.customerA, merge.customerB)"
      >
        <b-container>
          <b-row class="mb-4">
            <b-col>{{ $t('customer.merge-select') }}</b-col>
            <b-col><b-select v-model="merge.customerA" :options="customerOptions" /></b-col>
            <b-col><b-select v-model="merge.customerB" :options="customerOptions" /></b-col>
          </b-row>
          <b-form>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeCustomerFullName" :label="$t('customer.name')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeCustomerFullName"
                  type="text"
                  v-model="merge.customerA.fullName"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeCustomerFullName"
                  type="text"
                  v-model="merge.customerB.fullName"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group
                  label-for="mergeCustomerCustomerCode"
                  :label="$t('customer.customerCode')"
                  label-align="right"
                />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeCustomerCustomerCode"
                  type="text"
                  v-model="merge.customerA.customerCode"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <b-form-input
                  id="mergeCustomerCustomerCode"
                  type="text"
                  v-model="merge.customerB.customerCode"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeCustomerAdress" :label="$t('customer.address')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerAdress" type="text" v-model="merge.customerA.address" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerAdress" type="text" v-model="merge.customerB.address" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeCustomerCity" :label="$t('customer.city')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerCity" type="text" v-model="merge.customerA.city" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerAdress" type="text" v-model="merge.customerB.city" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeCustomerZip" :label="$t('customer.zipCode')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerZip" type="text" v-model="merge.customerA.zipCode" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerZip" type="text" v-model="merge.customerB.zipCode" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="mergeCustomerEMail" :label="$t('general.e-mail')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerEMail" type="text" v-model="merge.customerA.email" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="mergeCustomerEMail" type="text" v-model="merge.customerB.email" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group
                  label-for="mergeCustomerHourlyRateInCents"
                  :label="$t('customer.hourlyRate')"
                  label-align="right"
                />
              </b-col>
              <b-col>
                <currency-input
                  id="mergeCustomerHourlyRateInCents"
                  :value="merge.customerA.hourlyRateInCents"
                  :locale="$store.getters.getLocale"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <currency-input
                  id="mergeCustomerHourlyRateInCents"
                  :value="merge.customerB.hourlyRateInCents"
                  :locale="$store.getters.getLocale"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form-group label-for="mergeCustomerProjects" :label="$t('customer.projects')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-textarea
                  id="mergeCustomerProjects"
                  rows="3"
                  type="text"
                  v-model="formattedProjectsCustomerA"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <b-form-textarea
                  id="mergeCustomerProjects"
                  rows="3"
                  type="text"
                  v-model="formattedProjectsCustomerB"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4" />
              <b-col sm="8">
                <b-alert :show="customersSelected" variant="info">
                  {{
                    $t('customer.merge-modal-message', [this.merge.customerA.fullName, this.merge.customerB.fullName])
                  }}
                </b-alert>
                <b-alert :show="isSameCustomer" variant="danger">
                  {{ $t('customer.merge-identical-msg') }}
                </b-alert>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { validationMixin } from 'vuelidate'
import { required, integer, minLength, maxLength } from 'vuelidate/lib/validators'
import { GoldflamEmail } from '@/util/VuelidateValidators'
import { cloneDeep as _cloneDeep } from 'lodash'
import CurrencyInput from '@/components/CurrencyInput.vue'

export default {
  name: 'ManageCustomers',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { CurrencyInput, GoldflamTable },

  data() {
    return {
      objectModels: {
        customer: {
          id: null,
          fullName: '',
          email: '',
          address: '',
          city: '',
          zipCode: '',
          customerCode: '',
          hourlyRateInCents: 0
        }
      },
      editCustomer: {},
      merge: {
        customerA: {},
        customerB: {}
      },
      allCustomers: [],
      tableFields: [
        { key: 'customerCode', label: this.$t('customer.customerCode') },
        { key: 'fullName', label: this.$t('customer.name'), sortable: true },
        { key: 'address', label: this.$t('customer.address') },
        { key: 'city', label: this.$t('customer.city') },
        { key: 'zipCode', label: this.$t('customer.zipCode') },
        { key: 'email', label: this.$t('general.e-mail') },
        { key: 'hourlyRateInCents', label: this.$t('customer.hourlyRate'), formatter: 'formatMoney' },
        { key: 'projectNames', label: this.$t('customer.projects'), formatter: 'formatStringArray' },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '100px' }
      ],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      datatableBusy: false,
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        },
        merge: {
          show: false
        }
      }
    }
  },
  validations: {
    editCustomer: {
      fullName: {
        required,
        maxLength: maxLength(255)
      },
      email: {
        required,
        GoldflamEmail
      },
      address: {
        maxLength: maxLength(255)
      },
      city: {
        maxLength: maxLength(255)
      },
      zipCode: {
        integer,
        maxLength: maxLength(5)
      },
      customerCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(6)
      },
      hourlyRateInCents: {
        integer,
        maxLength: maxLength(11)
      }
    }
  },
  created() {
    this.editCustomer = _cloneDeep(this.objectModels.customer)
    this.merge.customerA = _cloneDeep(this.objectModels.customer)
    this.merge.customerA.projectNames = []
    this.merge.customerB = _cloneDeep(this.objectModels.customer)
    this.merge.customerB.projectNames = []
  },
  mounted() {
    this.datatableBusy = true
    this.loadCustomerList().finally(() => {
      this.datatableBusy = false
    })
  },
  computed: {
    customerOptions() {
      return this.allCustomers
        .map(customer => {
          return { value: customer, text: customer.fullName }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    formattedProjectsCustomerA() {
      return this.formatStringArray_merge(this.merge.customerA.projectNames)
    },
    formattedProjectsCustomerB() {
      return this.formatStringArray_merge(this.merge.customerB.projectNames)
    },
    customersSelected() {
      return this.merge.customerA.id !== null && this.merge.customerB.id !== null
    },
    isSameCustomer() {
      return this.merge.customerA.id === this.merge.customerB.id
    },
    newCustomerObject() {
      return _cloneDeep(this.objectModels.customer)
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editCustomer[prop]
      return $dirty ? !$invalid : null
    },
    saveCustomer(customer) {
      let url = '/customers'
      if (customer.id) {
        url += `/${customer.id}`
      }
      let self = this
      this.postRequest(
        url,
        customer,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('customer.success-msg'))
          self.loadCustomerList()
        })
      )
    },
    editCustomerFunction(customer) {
      this.editCustomer = _cloneDeep(customer)
      this.modalControl.edit.show = true
    },
    deleteCustomer(customer) {
      let self = this
      const id = customer.id
      this.deleteRequest(
        `/customers/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('customer.delete-success-msg'))
          self.loadCustomerList()
        })
      )
    },
    loadCustomerList() {
      let self = this
      return this.getRequest(
        '/customers',
        new RequestConfig().onSuccess(res => {
          self.allCustomers = res.data
        })
      )
    },
    mergeCustomer(customerA, customerB) {
      const idA = customerA.id
      const idB = customerB.id
      let self = this
      this.postRequest(
        `/customers/${idA}/merge/${idB}`,
        {},
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('customer.merge-success-msg'))
          self.loadCustomerList()
        })
      )
    },
    mergeCustomerFunction(customerA, customerB) {
      this.merge.customerA = Object.assign({}, customerA)
      this.merge.customerB = Object.assign({}, customerB)
      if (!this.merge.customerB.projectNames) {
        this.merge.customerB.projectNames = []
      }
      this.modalControl.merge.show = true
    },
    formatStringArray_merge(stringArray) {
      return Array.from(stringArray).sort().join('\n')
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editCustomer = _cloneDeep(this.objectModels.customer)
      this.$v.$reset()
    }
  }
}
</script>

<style scoped></style>

<template>
  <!-- Using this with a <b-input> has a bug in Firefox, therefore we use a plain <input class="form-control"> -->
  <input
    :class="['form-control', `form-control-${size}`, 'currencyInput', getValidClass]"
    ref="inputRef"
    :value="formattedValue"
    :disabled="disabled"
  />
</template>

<script setup>
import { useCurrencyInput } from 'vue-currency-input'
import { computed, defineProps } from 'vue'

const props = defineProps({
  value: { Number, default: 0 },
  locale: String,
  state: { Boolean, default: null },
  disabled: { Boolean, default: false },
  size: { String, default: 'md' },
  options: {
    type: Object,
    default: () => {
      return {
        locale: 'de-DE',
        currency: 'EUR',
        currencyDisplay: 'symbol',
        valueRange: {
          min: 0
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        valueScaling: 'precision',
        useGrouping: true,
        accountingSign: false
      }
    }
  }
})

const localOptions = computed(() => {
  let options = props.options
  if (props.locale) {
    options.locale = props.locale === 'de' ? 'de-DE' : 'en-US'
  }
  return options
})

const getValidClass = computed(() => {
  if (props.state === null) {
    return ''
  } else {
    return props.state ? 'is-valid' : 'is-invalid'
  }
})

const { inputRef, formattedValue } = useCurrencyInput(localOptions.value)
</script>
